import React from 'react';

import {Navbar, Nav, Container} from 'react-bootstrap';

const Navigation = () =>{
    return(
        <Container>
            <Navbar fixed="top" bg="light" expand="lg">
                <Navbar.Brand href="/">
                    Telep Remodeling LLC
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll" >
                    <Nav
                    className="mr-auto my-2 my-lg-0"
                    style={{ maxHeight: '100px' }}
                    navbarScroll
                    >
                        <Nav.Link href="/">HOME</Nav.Link>
                        
                        <Nav.Link href="/Services">SERVICES</Nav.Link>
                        <Nav.Link href="/Contact">CONTACT</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    );
}
export default Navigation;
