import React from 'react';

//Images
import Transparent_Logo from '../images/logo_transparent.png';

import {Jumbotron, Container, Row, Col} from 'react-bootstrap';

const ComingSoon = () => {
    return(
        <Jumbotron>
            <Container fluid className="Padding-Top">
                    <Row>
                        <Col  md={6}>
                            <img 
                                src={Transparent_Logo}
                                width= "300"
                                height="300"
                                className="center-text"
                                alt="Telep Remodeling LLC"
                            />
                        </Col>
                        <Col sm={4} className="flip-orange">
                            <h1 className="center-text">
                                This page is under construction.
                            </h1>                            
                        </Col>
                    </Row>                    
                </Container>

        </Jumbotron>
    );
}

export default ComingSoon;