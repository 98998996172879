import React from 'react';

import ComingSoon from './ComingSoon';

const Testimonials = () => {
    return(
        <div>
            <ComingSoon />
            Testimonials
        </div>
    );
}

export default Testimonials;