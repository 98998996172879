import React from 'react';

//Components
import Route from './Route';
import Navigation from './Navigation';
import Home from './Home';
import About from './About';
import Services from './Services';
import Testimonials from './Testimonials'
import Contact from './Contact';

class App extends React.Component {
    render(){
        return (
            <div>
                <Navigation />
                <Route path="/">
                    <Home />  
                </Route>
                <Route path="/About">
                    <About />  
                </Route>
                <Route path="/Services">
                    <Services />  
                </Route>
                <Route path="/Testimonials">
                    <Testimonials />  
                </Route>
                <Route path="/Contact">
                    <Contact />  
                </Route>
            </div>
        );
    
    }
}
export default App;
