import React from 'react';

import {Container, Jumbotron, Row} from 'react-bootstrap';

import Only_Transparent_Logo from '../images/Only_logo_transparent.png';
import Footer from './Footer';

const About = () => {
    return(
        <div>
            <Jumbotron fluid >
                <Container fluid className="Padding-Top">
                    <div className = "Left-Aligned">
                        <h1>ABOUT US</h1>
                        <br />
                        <p>
                            This will be populated soon!
                        </p>
{/*                         <br />
                        <p>
                            
                        </p>
                        <br />
                        <p>
                            
                        </p>
                        <br />
                        <p>
                            
                        </p>
                        <br />
                        <p>
                            
                        </p> */}
                    </div>
                </Container>
            </Jumbotron>
            <Jumbotron fluid className="flip-white">
                <Container>
                    <Row className="justify-content-md-center">
                        <img 
                                src={Only_Transparent_Logo}
                                width= "500"
                                height="300"
                                className="center-text"
                                alt="Telep Remodeling LLC"
                            />
                    </Row>
                </Container>                
            </Jumbotron>
            <Footer />
        </div>
    );
}

export default About;