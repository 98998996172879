import React, { useState } from 'react';
import emailjs from 'emailjs-com';

import {Jumbotron, Form, Button, Container, Row, Col, Alert} from 'react-bootstrap';
import Only_Transparent_Logo from '../images/Only_logo_transparent.png';
import Footer from './Footer';

const {REACT_APP_EMAILJS_SERVICE_ID, REACT_APP_EMAILJS_TEMPLATE_ID, REACT_APP_EMAILJS_USER_ID} = process.env;

const Contact = () => {
    //Form State - removes the form once it is submitted to help bot responses.
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [warning, setWarning] = useState(false);

    function sendEmail(e) {
        if((e.target.email.value.length > 0 || e.target.phone.value.length > 7) && (e.target.subject.value.length > 0 && e.target.subject.value !== 'Select a type')){
            e.preventDefault();
            emailjs.sendForm(`${REACT_APP_EMAILJS_SERVICE_ID}`,`${REACT_APP_EMAILJS_TEMPLATE_ID}`, e.target, `${REACT_APP_EMAILJS_USER_ID}`)
            .then((result) =>{
                console.log(result.text);
                setFormSubmitted(true);
            }, (error) => {
                console.log(error.text);
            }); 
        }else{
            e.preventDefault();    
            setWarning(true);
        }
    };
    return(
        <Container fluid>
            { !formSubmitted ?
            <div>
                <Container fluid className="Padding-Top">
                    <Row className="justify-content-md-center">
                        <Col  xs lg="10">
                        <div className="Email-Form">
                            <h1 >Request Carpentry Work</h1>
                            {warning ? 
                                  <Alert variant="danger" onClose={() => setWarning(false)} dismissible>
                                    <Alert.Heading>Error on submission.</Alert.Heading>
                                    <p>
                                        You must include a valid email OR a valid phone as well as select a type of work before submitting.
                                    </p>
                                </Alert>
                            : ''}
                            <Form onSubmit={sendEmail}>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>*Email address</Form.Label>
                                    <Form.Control  
                                        type="email" 
                                        placeholder="d.telep@yahoo.com" 
                                        name="email"
                                    />
                                    <Form.Text className="text-muted"></Form.Text>
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>*Phone Number</Form.Label>
                                    <Form.Control  placeholder="(216) 857-4058" name="phone"/>
                                    <Form.Text className="text-muted"></Form.Text>  
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>*Type of work</Form.Label>
                                        <br />
                                        <select name="subject" aria-label="Default select example">
                                        <option>Select a type</option>
                                        <option value="Tile">Tile</option>
                                        <option value="Flooring">Flooring</option>
                                        <option value="Trim">Trim</option>
                                        <option value="Drywall">Drywall</option>  
                                        <option value="Cabinets">Cabinets</option>
                                        <option value="Bath Remodel">Bath Remodel</option>
                                        <option value="Kitchen Remodel">Kitchen Remodel</option>
                                        <option value="Basement Finish">Basement Finish</option>
                                        <option value="Framing">Framing</option>
                                        <option value="Other">Other</option>
                                        </select>
                                    </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Describe what you need done" name="message"/>
                                </Form.Group>
                                <Button variant="secondary" size="lg" type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </div>
                        </Col>
                    </Row>                    
                </Container>
                <Jumbotron fluid className="flip-white">
                    <Container>
                        <Row className="justify-content-md-center">
                            <img 
                                    src={Only_Transparent_Logo}
                                    width= "500"
                                    height="300"
                                    className="center-text"
                                    alt="Telep Remodeling LLC"
                                />
                        </Row>
                    </Container>                
                </Jumbotron>
                <Footer />
            </div>
            :  
            <div>
                
                <Jumbotron fluid className="flip-white">
                    <h1>Thank you very much for your submission!</h1>
                    <Container>
                        <Row className="justify-content-md-center">
                            <img 
                                    src={Only_Transparent_Logo}
                                    width= "500"
                                    height="300"
                                    className="center-text"
                                    alt="Telep Remodeling LLC"
                                />
                        </Row>
                    </Container>                
                </Jumbotron>
                <Footer />
            </div>
            }
        </Container>
    );
}

export default Contact;
