import React from 'react';

import {Container, Jumbotron, Card, Row, Col} from 'react-bootstrap';
import Footer from './Footer';
//Images displayed in the cards

//First Row
import bathroom from '../images/Bathroom.png';
import bathroom_full from '../images/Bahtroom_Full.png';
import Shower from '../images/Shower.png';

//Second Row
import Countertop from '../images/CounterTop.png';

import Only_Transparent_Logo from '../images/Only_logo_transparent.png';

const Services = () => {
    return(
        <div>
            <Jumbotron fluid >
                <Container>
                    <h1>Tiling and Bathroom Remodeling</h1>
                    <br />
                    <Row>
                        <Col>
                            <Card style={{ width: '20rem' }}>
                                <Card.Img variant="top" src={bathroom} />
                                <Card.Body>
                                    <Card.Title>Bathroom</Card.Title>
                                    <Card.Text>
                                    Bathroom Finish
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ width: '20rem' }}>
                                <Card.Img variant="top" src={Shower} />
                                <Card.Body>
                                    <Card.Title>Shower Tiling</Card.Title>
                                    <Card.Text>
                                    Tiled entire shower
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col  >
                            <Card style={{ width: '20rem' }}>
                                <Card.Img variant="top" src={bathroom_full} />
                                <Card.Body>
                                    <Card.Title>Bathroom Tiling</Card.Title>
                                    <Card.Text>
                                        Tiled bathroom floor
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Jumbotron fluid className="flip-white">
                <Container>
                <h1>Kitchen</h1>
                    <Row>
                        <Col>
                            <Card style={{ width: '20rem' }}>
                                <Card.Img variant="top" src={Countertop} />
                                <Card.Body>
                                    <Card.Title>Countertop</Card.Title>
                                    <Card.Text>
                                    Replaced Countertop
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* <Col>
                            <Card style={{ width: '20rem' }}>
                                <Card.Img variant="top" src={Shower} />
                                <Card.Body>
                                    <Card.Title>asdf</Card.Title>
                                    <Card.Text>
                                    Replacing damaged Drywall
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ width: '20rem' }}>
                                <Card.Img variant="top" src={Shower} />
                                <Card.Body>
                                    <Card.Title>asdf</Card.Title>
                                    <Card.Text>
                                    Replacing damaged Drywall
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col> */}
                    </Row>

                </Container>
            </Jumbotron>
            <Jumbotron fluid >
                {/* <Container>
                    <h1>Tiling</h1>
                    <Row>
                        <Col>
                            <Card style={{ width: '20rem' }}>
                                <Card.Img variant="top" src={bathroom} />
                                <Card.Body>
                                    <Card.Title>Bathroom</Card.Title>
                                    <Card.Text>
                                    Bathroom Finish
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{ width: '20rem' }}>
                                <Card.Img variant="top" src={Shower} />
                                <Card.Body>
                                    <Card.Title>Shower Tiling</Card.Title>
                                    <Card.Text>
                                    Tiled entire shower
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col  >
                            <Card style={{ width: '20rem' }}>
                                <Card.Img variant="top" src={bathroom_full} />
                                <Card.Body>
                                    <Card.Title>Bathroom Tiling</Card.Title>
                                    <Card.Text>
                                        Tiled bathroom floor
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container> */}
            </Jumbotron>

            <Jumbotron fluid className="flip-white">
                <Container>
                    <Row className="justify-content-md-center">
                        <img 
                                src={Only_Transparent_Logo}
                                width= "500"
                                height="300"
                                className="center-text"
                                alt="Telep Remodeling LLC"
                            />
                    </Row>
                </Container>                
            </Jumbotron>
            <Footer />
        </div>
    );
}

export default Services;