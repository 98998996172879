import React from 'react';


//Images
import Transparent_Logo from '../images/logo_transparent.png';
import Only_Transparent_Logo from '../images/Only_logo_transparent.png';
//React-Bootstrap objects
import {Container,Jumbotron,Row, Col } from 'react-bootstrap';

import Footer from './Footer';

const Home = () => {
    return(
        <div>
            <Container fluid className="Padding-Top">
                <Row>
                    <Col  md={6}>  
                        <img 
                            src={Transparent_Logo}
                            width= "300"
                            height="300"
                            className="center-text"
                            alt="Telep Remodeling LLC"
                        />
                    </Col>
                    <Col  className="flip-orange">
                        <div>
                            <br />
                            <br />
                            <br />
                            <h1 className="inline">FAIR AND SQUARE </h1>
                            <br />
                            <h1>HONEST PRICING</h1> 
                            <br />
                            <h1>PROFESSIONAL WORK</h1>
                            <br />
                            <br />
                        </div>
                    </Col>
                </Row>                    
            </Container>
            <Jumbotron fluid className="flip-white">
                <Container>
                    <Row>
                        <Col  md={5}>
                            <h1>Schedule a service</h1>
                            <h2>David Telep - Owner</h2>
                            <h2><a href="tel:216-857-4058">(216) 857-4058</a></h2>
                            <h2>d.telep@yahoo.com</h2>
                        </Col>
                        <Col sm={5} >
                            <h2>Leave a voicemail and brief description, we'll call
                                right back with an initial quote.</h2>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Jumbotron fluid >
                <Container>
                    <Row>
                        <Col >
                            <h1>Kitchen & Bathroom Remodeling & Renovation</h1>
                            <h3>Professional Services.</h3>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Jumbotron fluid className="flip-white">
                <Container>
                    <Row>
                        <Col  md={5}>
                            <h1>Drywall and Painting</h1>
                            <h3>Install drywall to walls or ceilings</h3>
                            <h3>Interior/Exterior Painting</h3>
                        </Col>
                        <Col sm={5} >
                            <h1>Flooring</h1>
                            <h3>Hardwood, Laminate, Vinyl, or Tile</h3>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Jumbotron fluid >
                <Container>
                    <Row>
                        <Col  md={5}>
                            <h1>Carpentry</h1>
                            <h3>Finish Trim, Cabinetry, and Rough Framing</h3>
                        </Col>
                        <Col sm={5} >
                            <h1>Outdoors</h1>
                            <h3>Decks, Fencing, or Landscaping </h3>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Jumbotron fluid className="flip-white">
                <Container>
                    <Row className="justify-content-md-center">
                        <img 
                                src={Only_Transparent_Logo}
                                width= "500"
                                height="300"
                                className="center-text"
                                alt="Telep Remodeling LLC"
                            />
                    </Row>
                </Container>                
            </Jumbotron>
            <Footer />
        </div>
    );
}

export default Home;