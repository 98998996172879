import React from 'react';

import {Jumbotron, Container, Row, Col} from 'react-bootstrap';

const Footer = () =>{
    return(
        <div className="position-bottom">
            <Jumbotron fluid>
                <Container>
                    <Row>
                        <Col  md={6}>
                        <h2>Telep Remodeling LLC</h2> 
                        </Col>
                        <Col sm={4} >
                        <h2>North Eastern Ohio</h2>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <p className="Copyright">
                Copyright <i className="copyright outline icon"></i> 2021 Telep Remodeling LLC
            </p>
            <br/>
        </div>
    );
}

export default Footer;